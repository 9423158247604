import axios from 'axios';
import { config } from '../Configs';

export const LoginAPI = async (email, password, captchaToken) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    const response = await axios.post(
      `${config.api.url}/login`,
      {
        email,
        password
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const SignupAPI = async ({
  email,
  password,
  identification,
  identificationType,
  firstName,
  lastName = null,
  captcha
}) => {
  try {
    const response = await axios.post(`${config.api.url}/users`, {
      email,
      password,
      identification,
      identificationType,
      firstName,
      lastName,
      'g-recaptcha-response': captcha
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const TransactionSignupAPI = async ({
  email,
  password,
  firstName,
  lastName,
  identification,
  identificationType,
  phone,
  phoneCountryCode,
  contractId
}) => {
  try {
    const response = await axios.post(
      `${config.api.url}/users/transaction-signup`,
      {
        email,
        password,
        firstName,
        lastName,
        identification,
        identificationType,
        phone,
        phoneCountryCode,
        contractId
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GoogleSignUpAPI = async ({
  email,
  firstName,
  lastName,
  idToken,
  contractId
}) => {
  try {
    const response = await axios.post(`${config.api.url}/users/google`, {
      email,
      firstName,
      lastName,
      idToken,
      contractId
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const AppleSignUpAPI = async ({
  email,
  firstName,
  lastName,
  idToken
}) => {
  try {
    const response = await axios.post(`${config.api.url}/users/apple`, {
      email,
      firstName,
      lastName,
      idToken,
      clientId: config.apple.clientId
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const FacebookSignUpAPI = async ({
  email,
  firstName,
  lastName,
  accessToken
}) => {
  try {
    const response = await axios.post(`${config.api.url}/users/facebook`, {
      email,
      firstName,
      lastName,
      idToken: accessToken
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ForgotPasswordAPI = async (email, recaptchaToken) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': recaptchaToken }
    };
    const response = await axios.post(
      `${config.api.url}/login/recover`,
      {
        email
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ConfirmPasswordAPI = async (token, password) => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/confirm`,
      {
        password
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidateIdentificationAPI = async (
  token = null,
  data,
  captcha
) => {
  try {
    const configObj = token ? { headers: { Authorization: token } } : {};

    const response = await axios.post(
      `${config.api.url}/users/validateIdentification`,
      { ...data, 'g-recaptcha-response': captcha },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const VerifyAccountAPI = async token => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/verify`,
      {},
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ResendEmailValidation = async email => {
  try {
    const response = await axios.post(
      `${config.api.url}/users/resendValidation`,
      { email }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const UpdateUserAPI = async (token, id, data) => {
  try {
    const response = await axios.put(`${config.api.url}/users/${id}`, data, {
      headers: {
        Authorization: token
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const UpdateUserDataAPI = async (token, id, data) => {
  try {
    const response = await axios.put(
      `${config.api.url}/users/profile/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const ValidateUserDataAPI = async (token, id, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/users/validate/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const UpdateUserPasswordAPI = async (token, id, data) => {
  try {
    const response = await axios.put(
      `${config.api.url}/users/password/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const VerifyPhoneNumberAPI = async (token, code) => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/verify/phone`,
      { code },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const ResendPhoneConfirmationAPI = async token => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/resend/phone`,
      {},
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const RefreshTokenAPI = async token => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/refresh`,
      {},
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ChangeEmailAPI = async data => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/email`,
      data,
      {}
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const DeleteUserAPI = async (token, id, data = null) => {
  try {
    const response = await axios.delete(`${config.api.url}/users/${id}`, {
      data,
      headers: {
        Authorization: token
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const UpdateUserIdentificationAPI = async (
  token,
  id,
  { identification, identificationType, queryId }
) => {
  try {
    const response = await axios.put(
      `${config.api.url}/users/identification/${id}`,
      {
        identification,
        identificationType,
        queryId,
        validatorAppId: 'portalweb',
        originAppId: 'portalweb'
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const SetUserPasswordAPI = async (token, password) => {
  try {
    const response = await axios.post(
      `${config.api.url}/login/update-password`,
      {
        password
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetTokenValidationAPI = async token => {
  try {
    const response = await axios.get(
      `${config.api.url}/login/validate-password-update`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
